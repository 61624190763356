import ReactPlayer from 'react-player/youtube';

import { VideoPlayerProps } from './VideoPlayer.types';
import styled from 'styled-components';
import { media } from '../../../styles/media';

export const VideoPlayer = ({ url, width, height }: React.PropsWithChildren<VideoPlayerProps>) => {
  return (
    <Wrapper>
      <ReactPlayer className="react-player" url={url} width="100%" height="100%" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
